import {Component, OnInit} from '@angular/core';

export interface RouteInfo{
	path: string;
	title: string;
	icon: string;
	class: string;
}

export const ROUTES: RouteInfo[] = [
	{path: '/admin/dashboard', title: 'Dashboard', icon: 'nc-bank', class: ''},
	{path: '/admin/brand', title: 'Brand', icon: 'nc-app', class: ''},

	{path: '/admin/category', title: 'Category', icon: 'nc-badge', class: ''},
	{path: '/admin/product', title: 'Product', icon: 'nc-shop', class: ''},
  {path: '/admin/attribute', title: 'Attribute', icon: 'nc-shop', class: ''},
  {path: '/admin/attribute-values', title: 'Attribute Values', icon: 'nc-shop', class: ''},
	// {path: '/admin/testimonial', title: 'Testimonial', icon: 'nc-book-bookmark', class: ''},
	// {path: '/admin/policy', title: 'Policy', icon: 'nc-bulb-63', class: ''},
	{path: '/admin/customer', title: 'Customer', icon: 'nc-credit-card', class: ''},
  {path: '/admin/subscriber', title: 'Subscriber', icon: 'nc-credit-card', class: ''},
	// {path: '/admin/seller-requests', title: 'Seller Request', icon: 'nc-paper', class: ''},
	{path: '/admin/inquiry', title: 'Inquiry', icon: 'nc-check-2', class: ''},
	{path: '/admin/orders', title: 'Orders', icon: 'nc-money-coins', class: ''},
	{path: '/admin/reports', title: 'Reports', icon: 'nc-bookmark-2', class: ''},
	{path: '/admin/vendor', title: 'Vendor', icon: 'nc-support-17', class: ''},
	{path: '/admin/coupon', title: 'Coupon', icon: 'nc-favourite-28', class: ''},
	{path: '/admin/slider', title: 'Slider', icon: 'nc-tile-56', class: ''},
	{path: '/admin/banner', title: 'Banner', icon: 'nc-image', class: ''},
	{path: '/admin/user', title: 'My Profile', icon: 'nc-single-02', class: ''},
	{path: '/admin/website-details', title: 'Website Details', icon: 'nc-globe', class: ''},
];

export const VENDOR_ROUTES: RouteInfo[] = [
	{path: '/vendor/dashboard', title: 'Dashboard', icon: 'nc-bank', class: ''},
	{path: '/vendor/product', title: 'Product', icon: 'nc-shop', class: ''},
	{path: '/vendor/inquiry', title: 'Inquiry', icon: 'nc-check-2', class: ''},
	{path: '/vendor/orders', title: 'Orders', icon: 'nc-money-coins', class: ''},
	{path: '/vendor/reports', title: 'Reports', icon: 'nc-bookmark-2', class: ''},
	{path: '/vendor/user', title: 'User Profile', icon: 'nc-single-02', class: ''},
];

@Component({
	           moduleId   : module.id,
	           selector   : 'sidebar-cmp',
	           templateUrl: 'sidebar.component.html',
	           styleUrls  : ['sidebar.component.scss'],
           })

export class SidebarComponent implements OnInit{
	public menuItems: any[];
	public AdminData = JSON.parse(localStorage.getItem('AdminData'));
	ngOnInit(){
		if(this.AdminData.type==1){
			this.menuItems = ROUTES.filter(menuItem => menuItem);
		} else if(this.AdminData.type==2){
			this.menuItems = VENDOR_ROUTES.filter(menuItem => menuItem);
		}

	}
}
