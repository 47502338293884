import {Component, Input, OnInit}    from '@angular/core';
import {HttpEventType, HttpResponse} from '@angular/common/http';

import {StorageS} from '../../service/storage';
import {CommonHelper}  from '../../service/common-helper';

@Component({
	           moduleId   : module.id,
	           selector   : 'app-product-upload-image',
	           templateUrl: 'product-upload-images.component.html'
           })

export class ProductUploadFilesComponent implements OnInit {
	@Input() model: any;
	@Input() baseUrl: any;
  @Input() selAttributeValues: any;
	public showPreview: boolean  = false;
	public showVideoPreview: boolean  = false;
	public showBrochurePreview: boolean  = false;
	selectedFiles: FileList;
	selectedVideo: FileList;
	selectedBrochure: FileList;
	progressInfos                = [];
	message                      = '';
	serverPath                   = 'https://medioffers.s3.ap-south-1.amazonaws.com/modules/';
	public previousIndex: number = 0;
	public headImg: any;
  public orderBy: any;
  public colors: any;
  public selectedColors: any;
  //public selAttributeValues: any;



	constructor(private storageS: StorageS){

    this.orderBy = [{id: 1, nm: '1'}, {id: 2, nm: '2'}, {id: 3, nm: '3'},{id: 4, nm: '4'},{id: 5, nm: '5'},{id: 6, nm: '6'},{id: 7, nm: '7'},{id: 8, nm: '8'},{id: 9, nm: '9'},{id: 10, nm: '10'}];
    /*this.colors = [
			{"id":1,"name":"Black"},
			{"id":2,"name":"Green"},
			{"id":3,"name":"Orange"},
			{"id":4,"name":"Violet"},
      {"id":4,"name":"Red"},
      {"id":4,"name":"White"}
		];*/

   //this.selAttributeValues = [{id: 1, name: 'pro_L_Black'},{id: 7, name: 'pro_S_Black'}];

  }

	ngOnInit(): void{

    this.storageS.getByCustom({type: 'color'}, 'attribute-value').subscribe((response) => {

      this.colors = response.data;

      CommonHelper.attributeValues$.subscribe(attributeValues => {
        this.selAttributeValues = attributeValues;
      });

      this.selectedColors = this.colors.filter(color => {
        return this.selAttributeValues.some(value => value.name.includes(color.name));
      }).map(selectedColor => {
        return { ...selectedColor, disabled: false };
      });

      }, error => {
        console.log(error);
      });



		if(this.model && this.model.zf && this.model.zf.length){
			this.showPreview = true;
			this.selectImage(0);
		}

		if(this.model && this.model.video){
			this.showVideoPreview = true;
		}
		if(this.model && this.model.brochure){
			this.showBrochurePreview = true;
		}


    CommonHelper.attributeValues$.subscribe(attributeValues => {
      this.selAttributeValues = attributeValues;
      console.log(this.colors);
      console.log(this.selAttributeValues);
    });

    this.selectedColors = this.colors.filter(color => {
      return this.selAttributeValues.some(value => value.name.includes(color.name));
    }).map(selectedColor => {
      return { ...selectedColor, disabled: false };
    });
    console.log(this.selectedColors);

	}

	getServerPath(url){
		return this.serverPath+ url;
	}

	buildFiles(){
		this.storageS.getFiles(this.baseUrl).subscribe((response) => {
			if(response && response.length){
				let isFirst = true;
				//for(let item of response){
				for(let file of this.progressInfos || []){
					if((file) && (file.name)){// === item.name
						let isExist = false;
						for(let zf of this.model.zf || []){
							if((zf) && (zf.nm === file.name)){
								isExist = true;
								break;
							}
						}
						if(!isExist){
							this.model.zf.push({nm: file.name, url: `${this.serverPath}${this.baseUrl}/img/${file.name}`, imgName: null, orderBy:null, a: isFirst ? 1 : 0});
							this.showPreview = true;
							if(isFirst){
								this.headImg = `${this.serverPath}${this.baseUrl}/img/${file.name}`;
							}
							isFirst = false;
						}
					}
				}
				//}
			}
			//console.log(this.model.zf);
      //console.log(this.selAttributeValues);

      // Extract variant names
      /*this.selectedColors = this.colors.filter(color => {
        return this.selAttributeValues.some(value => value.name.includes(color.name));
      });*/
      this.selectedColors = this.colors.filter(color => {
        return this.selAttributeValues.some(value => value.name.includes(color.name));
      }).map(selectedColor => {
        return { ...selectedColor, disabled: false };
      });

      this.selectedColors.unshift({"id": 0, "name": "Select", disabled: false },{"id": 1, "name": "Others",disabled: false });



     console.log(this.selectedColors);

		}, error => {
			console.log(error);
		});
	}

  updateColor(){

  }

  // Update name value in the model
  updateName(index: number, value: string): void {
    this.model.zf[index].imgName = value;
    const selectedColor = this.selectedColors.find(color => color.name === value);
    console.log(selectedColor);
    if (selectedColor && selectedColor.name !='Others') {
        selectedColor.disabled = true;
    }
    console.log(this.model.zf);

  }

  // Update order value in the model
  updateOrder(index: number, value: string): void {
    this.model.zf[index].orderBy = value;
  }

	removeImage(index){
		this.model.zf.splice(index, 1);
		if(this.model.zf.length){
			this.selectImage(0);
			this.showPreview = true;
		} else{
			this.showPreview = false;
		}
	}

	selectImage(index){
		this.model.zf[this.previousIndex].a = 0;
		this.previousIndex                  = index;
		this.model.zf[index].a              = 1;
		this.headImg                        = this.model.zf[index].url;
	}


	selectFiles(event): void{
		this.progressInfos = [];

		const files = event.target.files;
		let isImage = true;

		for(let i = 0; i < files.length; i++){
			if(files.item(i).type.match('image.*')){
				continue;
			} else{
				isImage = false;
				alert('invalid format!');
				break;
			}
		}

		if(isImage){
			this.selectedFiles = event.target.files;
			this.uploadFiles();
		} else{
			this.selectedFiles          = undefined;
			event.srcElement.percentage = null;
		}
	}

	selectVideo(event): void{
		this.progressInfos = [];

		const files = event.target.files;
		let isVideo = true;

		for(let i = 0; i < files.length; i++){
			if(files.item(i).type.match('video.*')){
				continue;
			} else{
				isVideo = false;
				alert('invalid format!');
				break;
			}
		}

		if(isVideo){
			this.selectedVideo = event.target.files;
			this.uploadVideo();
		} else{
			this.selectedFiles          = undefined;
			event.srcElement.percentage = null;
		}
	}

	selectBrochure(event): void{
		this.progressInfos = [];

		const files = event.target.files;
		let isPDF = true;

		for(let i = 0; i < files.length; i++){
			if(files.item(i).type.match('application/pdf')){
				continue;
			} else{
				isPDF = false;
				alert('invalid format!');
				break;
			}
		}

		if(isPDF){
			this.selectedBrochure = event.target.files;
			this.uploadBrochure();
		} else{
			this.selectedBrochure          = undefined;
			event.srcElement.percentage = null;
		}
	}

	triggerInput(event){
		const element = event.currentTarget;
		element.querySelector('input').click();
	}

	async uploadFiles(){
		this.message = '';
		let promises = [];
		for(let i = 0; i < this.selectedFiles.length; i++){
			if(this.selectedFiles[i]){
				promises.push(await this.upload(i, this.selectedFiles[i]));
			}
		}

		Promise.all(promises).then(()=>{
			this.buildFiles();
		})

	}

	async uploadVideo(){
		this.message = '';
		let promises = [];
		for(let i = 0; i < this.selectedVideo.length; i++){
			if(this.selectedVideo[i]){
				promises.push(await this.uploadVideoToServer(i, this.selectedVideo[i]));
			}
		}

		Promise.all(promises).then(()=>{
			this.showVideoPreview = true;
		})

	}

	async uploadBrochure(){
		this.message = '';
		let promises = [];
		for(let i = 0; i < this.selectedBrochure.length; i++){
			if(this.selectedBrochure[i]){
				promises.push(await this.uploadBrochureToServer(i, this.selectedBrochure[i]));
			}
		}

		Promise.all(promises).then(()=>{
			this.showBrochurePreview = true;
		})

	}

	upload(idx, file){
		return new Promise((resolve, reject)=>{
			this.progressInfos[idx] = {value: 0, name: file.name};
			this.storageS.pushFileToStorage(file, this.baseUrl).subscribe(event => {
				if(event.type === HttpEventType.UploadProgress){
					//this.progress.percentage = Math.round(100 * event.loaded / event.total);
				} else if(event instanceof HttpResponse){
					console.log('File is completely uploaded!');
					resolve(true);
				}
			});
		})
	}

	uploadVideoToServer(idx, file){
		return new Promise((resolve, reject)=>{
			this.progressInfos[idx] = {value: 0, name: file.name};
			this.storageS.pushVideoToStorage(file, this.baseUrl).subscribe(event => {
				if(event.type === HttpEventType.UploadProgress){
					//this.progress.percentage = Math.round(100 * event.loaded / event.total);
				} else if(event instanceof HttpResponse){
					this.model.video = event.body;
					resolve(true);
				}
			});
		})
	}

	uploadBrochureToServer(idx, file){
		return new Promise((resolve, reject)=>{
			this.progressInfos[idx] = {value: 0, name: file.name};
			this.storageS.pushBrochureToStorage(file, this.baseUrl).subscribe(event => {
				if(event.type === HttpEventType.UploadProgress){
					//this.progress.percentage = Math.round(100 * event.loaded / event.total);
				} else if(event instanceof HttpResponse){
					console.log(event.body)
					this.model.brochure = event.body;
					resolve(true);
				}
			});
		})
	}
}
